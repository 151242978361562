import {
	ROUTE_PRODUCTS_UPDATE_PRODUCTS_BULK_ACCESS,
	ROUTE_UPDATE_PRODUCT_BULK_ACTIONS_UPLOAD_LOGS_ACCESS,
	ROUTE_UPDATE_PRODUCT_BULK_ACTIONS_UPLOAD_LOGS_READ
} from 'constants/RouterNames'

const viewData = () =>
	import('components/pages/secured/product/productsUpdateProductsBulk/productsUpdateProductsBulk.vue')

const formPage = () =>
	import('components/pages/secured/product/productsUpdateProductsBulk/productsUpdateProductsBulk/productsUpdateProductsBulk.vue')

const logPage = () =>
	import('components/pages/secured/product/productsUpdateProductsBulk/productsProductsUpdateProductsBulkLogs/productsProductsUpdateProductsBulkLogs.vue')

const logDetailPage = () =>
	import('components/pages/secured/product/productsUpdateProductsBulk/productsProductsUpdateProductsBulkLogs/productsProductsUpdateProductsBulkLogsDetails/productsProductsUpdateProductsBulkLogsDetails.vue')

const productsUpdateProductsBulkRoute = {
	path: '/products/bulk',
	redirect: '/products/update-em-massa',
	component: viewData,
	name: ROUTE_PRODUCTS_UPDATE_PRODUCTS_BULK_ACCESS.name,
	meta: {
		breadcrumb: { name: 'Atualização de Produtos em Massa' },
		requiresAuthorities: ROUTE_PRODUCTS_UPDATE_PRODUCTS_BULK_ACCESS.routerAuth
	},
	children: [
		{
			path: '/products/update-em-massa',
			component: formPage,
			name: ROUTE_PRODUCTS_UPDATE_PRODUCTS_BULK_ACCESS.name,
			meta: { requiresAuthorities: ROUTE_PRODUCTS_UPDATE_PRODUCTS_BULK_ACCESS.routerAuth }
		},
		{
			path: '/products/update-em-massa/log',
			component: logPage,
			meta: {
				breadcrumb: { name: 'Logs' },
				requiresAuthorities: ROUTE_UPDATE_PRODUCT_BULK_ACTIONS_UPLOAD_LOGS_ACCESS.routerAuth
			},
			name: ROUTE_UPDATE_PRODUCT_BULK_ACTIONS_UPLOAD_LOGS_ACCESS.name
		},
		{
			path: '/products/update-em-massa/log/detalhe/:id',
			props: true,
			component: logDetailPage,
			meta: {
				breadcrumb: {
					name: 'Logs Detalhados'
				},
				requiresAuthorities:
					ROUTE_UPDATE_PRODUCT_BULK_ACTIONS_UPLOAD_LOGS_READ.routerAuth
			},
			name: ROUTE_UPDATE_PRODUCT_BULK_ACTIONS_UPLOAD_LOGS_READ.name
		}
	]
}

export default productsUpdateProductsBulkRoute
