import router from 'src/router/routerConfig'

const pushNextRoute = (route, params) => {
	router.push({ name: route.name, append: true, params })
}

const redirect = route => {
	router.push({ name: route.name || route })
}

const redirectUrl = path => {
	router.push({ path })
}

const replace = route => {
	router.replace({ name: route.name || route })
}

const redirectWithParam = (route, params) => {
	router.push({ name: route.name, params })
}

const redirectWithQuery = (route, query) => {
	router.push({ name: route.name, query })
}

const breadcrumbRedirect = route => {
	router.push(route)
}

export {
	redirect,
	redirectUrl,
	replace,
	redirectWithParam,
	redirectWithQuery,
	breadcrumbRedirect,
	pushNextRoute
}
