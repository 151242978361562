import AuthorityBuilder from 'pojos/AuthorityBuilder.js'

const SellerReputation = new AuthorityBuilder({
	accessGroup: 'SELLER_REPUTATION',
	accessType: ['ACCESS', 'READ']
})

export default [
	SellerReputation
]
