import {
	ROUTE_REPUTATION,
	ROUTE_REPUTATION_SELLER_READ
} from 'constants/RouterNames'

const ReputationPage = () =>
	import('components/pages/secured/reputation/reputation')

const sellerReputationRoute = {
	path: '/reputation',
	redirect: '/reputation-dashboard',
	component: ReputationPage,
	name: ROUTE_REPUTATION.name,
	meta: {
		breadcrumb: { name: 'Reputação dos Sellers' },
		requiresAuthorities: ROUTE_REPUTATION.routerAuth
	},
	children: [
		{
			path: '/reputation-dashboard',
			component: ReputationPage,
			name: ROUTE_REPUTATION_SELLER_READ.name,
			meta: {
				breadcrumb: { name: 'dashboard' },
				requiresAuthorities: ROUTE_REPUTATION_SELLER_READ.routerAuth
			}
		}
	]
}

export default sellerReputationRoute
